import {
  Cell,
  Conditional,
  RadioList,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import React, { useEffect } from 'react';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { spoolChangeOptions } from './step-prepare-machine-content.constants';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { spoolChangedTypes } from '../../../../../start-job/components/start-print-print-job-modal/start-print-print-job-modal.constants';
import { useFetchConfigurations } from '../../../../../../../../../api/configurations';
import { materialsOptions } from '../../../../../../../../../constants/parts';
import { AtomsRadioList } from '../../../../../../../../../components/common/atoms-radio-list';
import { StyledCell } from './step-prepare-machine-content.styles';
import { SpoolChanged } from '../../../../../start-job/components/start-print-print-job-modal/start-print-print-job-modal.types';
import {
  ChangeSpoolValue,
  configurationNames,
} from '../../../../../../../../../api/configurations/routes/fetch-configurations/fetch-configurations.types';

export const PrepareMachineContent = () => {
  // there is no need to have a global state (recoil) to store this data since the query have an infinity staleTime cache
  const { data } = useFetchConfigurations({
    fetchArgs: { names: [configurationNames.changeSpool] },
  });

  const inventoryTransactionSpoolDetails =
    data?.[configurationNames.changeSpool] || ({} as ChangeSpoolValue);

  const job = useRecoilValue(completePrintHarvestModalAtoms.job);
  const [spoolChanged, setSpoolChanged] = useRecoilState(
    completePrintHarvestModalAtoms.spoolChanged,
  );

  const [spoolWeight, setSpoolWeight] = useRecoilState(
    completePrintHarvestModalAtoms.spoolWeight,
  );

  const [materialChanged, setMaterialChanged] = useRecoilState(
    completePrintHarvestModalAtoms.materialChanged,
  );

  const details = inventoryTransactionSpoolDetails[materialChanged];

  useEffect(() => {
    setMaterialChanged(job?.material || job?.machine?.material);
  }, [job?.material]);

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Prepare machine" />

      <Stack gap="x">
        <Typography text="Spool Change" variant="h5" />
        <Typography text="Did you perform a spool change to start print on this machine?" />

        <RadioList
          items={spoolChangeOptions}
          showAsTiles
          value={spoolChanged}
          onSelected={(value: SpoolChanged) => {
            setSpoolChanged(value);
          }}
        />
      </Stack>

      <Cell gap="x">
        <Typography text="Current spool weight" variant="h5" />
        <TextField
          debounce={250}
          placeholder="Input weight"
          required
          rightIcon={<Typography color="t2" text="g" />}
          type="number"
          value={String(spoolWeight)}
          onTextChange={(value) => {
            setSpoolWeight(Number(value));
          }}
        />
      </Cell>

      <Conditional condition={spoolChanged !== spoolChangedTypes.noChange}>
        <StyledCell gap=".5x">
          <Typography text="Select material added:" variant="h6" />

          <AtomsRadioList
            atom={completePrintHarvestModalAtoms.materialChanged}
            idProperty="value"
            items={materialsOptions}
            labelProperty="labelText"
          />

          <Conditional
            condition={!!details && spoolChanged === spoolChangedTypes.yesNew}
          >
            <Typography
              color="t2"
              text={`A transaction of ${details?.weight}kg of #${details?.id} - ${details?.name} will be recorded.`}
              variant="p2"
            />
          </Conditional>
        </StyledCell>
      </Conditional>

      <Cell gap="x">
        <Typography text="Clean print bed" variant="h5" />
        <Typography text="Please ensure the print bed is ready for the next print." />
      </Cell>
    </Stack>
  );
};
