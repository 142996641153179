import React, { FC } from 'react';
import { Conditional, FormikInput, Grid, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { FormItem } from '../../../../inventory/components/form-item';
import { OverviewMachine } from '../../../../../api/machines/routes/get-overview';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';
import { ResetJobCount } from '../reset-job-count';
import {
  EditMachineDataFormDef,
  getFormattedMacAddress,
} from '../../../machine.schema';
import { bambuMachineTypes } from '../../../../../constants/machines';
import { MachineLocationInput } from '../../../../../components/common/machine-location-input';

export const OverviewFormSection: FC<{
  currentMachine: OverviewMachine;
  isEditModeActive?: boolean;
}> = ({ currentMachine, isEditModeActive }) => {
  const { setFieldValue, values } = useFormikContext<EditMachineDataFormDef>();

  const isBambuMachine = bambuMachineTypes.includes(values?.type);

  return (
    <Grid
      columns="1fr"
      gap="1.2rem"
      maxWidth={isEditModeActive ? '60rem' : 'unset'}
    >
      <FormItem
        isEditMode={isEditModeActive}
        label="Machine Name"
        orientation="row"
        viewElement={currentMachine.name}
      >
        <FormikInput name="name" placeholder="Input name" required />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Serial Number"
        orientation="row"
        viewElement={currentMachine.serialNumber || '-'}
      >
        <FormikInput name="serialNumber" placeholder="Input serial number" />
      </FormItem>

      <Conditional condition={isBambuMachine}>
        <FormItem
          isEditMode={isEditModeActive}
          label="MAC Address"
          orientation="row"
          viewElement={currentMachine.macAddress || '-'}
        >
          <FormikInput
            name="macAddress"
            placeholder="Input MAC address"
            onTextChange={async (value) => {
              const macAddress = getFormattedMacAddress(value);

              await setFieldValue('macAddress', macAddress);
            }}
          />
        </FormItem>

        <FormItem
          isEditMode={isEditModeActive}
          label="IP Address"
          orientation="row"
          viewElement={currentMachine.ipAddress || '-'}
        >
          <FormikInput
            name="ipAddress"
            placeholder="Input IP address"
            onTextChange={async (value) => {
              await setFieldValue('ipAddress', value.trim());
            }}
          />
        </FormItem>

        <FormItem
          isEditMode={isEditModeActive}
          label="Password"
          orientation="row"
          viewElement={currentMachine.password || '-'}
        >
          <FormikInput
            name="password"
            placeholder="Input password"
            onTextChange={async (value) => {
              await setFieldValue('password', value.trim());
            }}
          />
        </FormItem>
      </Conditional>

      <FormItem
        label="Created"
        orientation="row"
        viewElement={formatDate(currentMachine.createdAt, LONG_FORMAT)}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Spool Weight"
        orientation="row"
        viewElement={
          !!currentMachine.spoolWeight ? `${currentMachine.spoolWeight}g` : '-'
        }
      >
        <FormikInput
          name="spoolWeight"
          placeholder="Input weight"
          rightIcon={<Typography color="t2" text="g" />}
          type="number"
        />
      </FormItem>

      <FormItem
        label="Current Jobs"
        orientation="row"
        viewElement={String(currentMachine.currentJobsCount)}
      />

      <FormItem
        label="Past Jobs"
        orientation="row"
        viewElement={String(currentMachine.pastJobsCount)}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Location"
        orientation="row"
        viewElement={currentMachine.location}
      >
        <MachineLocationInput isEnabled={isEditModeActive} />
      </FormItem>

      <FormItem
        label="Prints on Build Plate"
        orientation="row"
        viewElement={<ResetJobCount currentMachine={currentMachine} />}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Lifetime Print Hours"
        orientation="row"
        viewElement={String(currentMachine.lifeTimePrintHours || '-')}
      >
        <FormikInput
          name="lifeTimePrintHours"
          placeholder="Input lifetime print hours"
          type="number"
        />
      </FormItem>

      <FormItem
        label="Last repair"
        orientation="row"
        viewElement={
          formatDate(currentMachine.lastRepairInfo?.date, LONG_FORMAT) || '-'
        }
      />

      <FormItem
        label="Yield"
        orientation="row"
        viewElement={`${currentMachine.yield}%`}
      />
    </Grid>
  );
};
